.main-heading-style-notification-details {
    font-size: 26px;
    align-items: center;
    font-weight: 800;
    padding: 15px;
}

.sub-heading-style-notification-details {
    font-size: 16px;
    align-items: center;
    font-weight: 600;
    padding: 15px;
}

.content-style-notification-details {
    font-size: 14px;
    align-items: center;
    font-weight: 550;
    padding: 15px;
}

.acknowledge-button-style-notification-details {
    padding-left: 45%;
}
