.cardStyle{
    display: flex;
    min-height: 135px;
    padding: 5px;
    border: 2px solid #e1e6eb
}

.imageBox{
    flex: 1;
    padding: 15px;
}

.messageBox{
    flex: 6;
    padding: 10px;
}

.innerFlexBox{
    display: flex;
    flex-direction: column;
}
  
.lockedUntilDate{
    width: 100px;
    flex: 1;
}