.sku-card {
    height: 60px;
    display:flex !important;
    padding: 4px 4px 4px 0;
    border: 1px solid #c4cdd6;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(196, 205, 214, 0.5);
    transition: opacity 1s ease-in-out;
    visibility: visible !important;
}

.sku-card.disabled {
    background-color: #EFF3F6;
    color: #c4cdd6;
    cursor: not-allowed;
}

.sku-card__info {
    flex-grow: 1;
    margin: 0 10px;
    align-self: center;
    min-width: 0;
    flex-basis: 100px;
}

.sku-card__info__top {
    font-weight: 500;
    font-size: 15px;
}

.sku-card__info__top, .sku-card__info__bottom {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.sku-card__info__top__name::before {
    content: ' | ';
}

.sku-card__info__bottom {
    margin-top: 9px;
}

.sku-card__img {
    text-align: center;
    flex-basis: 50px;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    background-color: #e1e6eb;
}

.sku-card__shape-container {
    padding-top: 4px;
    line-height: 50px;
}


.sku-card__drag {
    flex-shrink: 0;
    align-self: center;
    text-align: center;
    cursor: move; /* fallback if grab cursor is unspported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    pointer-events: auto;
}

.sku-card__drag:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

.sku-card__pre-action {
    flex-basis: 50px;
    flex-shrink: 0;
    align-self: center;
    text-align: center;
    cursor: pointer;
    pointer-events: auto;
}

.sku-card__pre-action__checkbox {
    padding-left: 10px; /* cancel out Mex component's padding on checkboxes */
}

.sku-card__post-action {
    flex-basis: 50px;
    flex-shrink: 0;
    align-self: center;
    text-align: center;
    pointer-events: auto;
}

.sku-card__post-action__link--disabled .injected-svg {
    fill: #35AD96;
}

.sku-card__post-action__link--disabled:hover .injected-svg {
    fill: #35AD96;
}

.sku-card__post-action__link--clickable {
    cursor: pointer;
}

.sku-card__post-action__link--clickable:hover .injected-svg {
    fill: #a7d4e2;
}

.sku-card__post-action__link--clickable:active .injected-svg {
    fill: #87b9c9;
}