.option-card {
    min-width: 350px;
    background-color: #ffffff;
    border: 1px solid #3295B5;
    box-shadow: 0 2px 4px 0 rgba(196, 205, 214 ,0.5);
    padding: 40px;
}

.option-card__top {
    /*border-bottom: 1px solid #E1E6EB;*/
}

.option-card__top__top {
    display: flex;
    justify-content: center;
    /*margin-bottom: 40px;*/
}

.option-card__top__top__icon {
    display: flex;
    align-items: center;
    justify-content: left;
    flex-basis: 80px;
    padding-bottom: 30px;
    margin-right: 20px;
}

.option-card__top__top__right {
    display: flex;
    flex-direction: column;
    min-width: 250px;
}

.option-card__top__description {
    color: #8090a2;
    font-size: 1.071em;
    margin-bottom: 60px;
}

.option-card__bottom {
    margin-top: 10px;
    text-align: center;
}