
.settings__identity-button {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
}

.settings__subscriptions-icon {
    margin-top: 17px;
    z-index: 1;
    margin-left: -30px;
}

.settings__auto-approval-icon {
    margin-top: 37px;
    padding-right: 35px;
    z-index: 1;
}

.settings__search-header {
    display: flex;
    justify-content: flex-end;
}

.settings__button-group {
    min-width: min-content;
}