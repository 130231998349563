.card-landing-body{
    flex: 0 0 30%;
}
.card-landing-header{
    display: flex;
    align-items: center;
    font-size: 12px;
    height: 1.4em;
}

.card-bottom-button{
    margin-top: 15px;
}


.card-header-title-with-icon{
    margin-left: 10px;
}
  