.flex-filterNotification{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .notificationDate .datetime .rdt {
    width: 180px;
  }
  
  .startDateFlexBox{
    flex: 1;
  }
  .endDateFlexBox{
    flex: 1;
    margin-left: 15px;
  }
  .productIdsFlexBox{
    flex: 6;
    margin-left: 15px;
  }