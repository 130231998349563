.key-value-list {
    margin: 0 auto 9px auto;
    list-style: none;
    display: table;
}

.key-value-list__row {
    display: table-row;
    border-bottom: 1px solid #E8E8E8;
}

.key-value-list__key {
    display: table-cell;
    width: 50%;
    font-weight: 700;
    text-align: right;
}

.key-value-list__key::after {
    content: ": ";
}

.key-value-list__value {
    display: table-cell;
    width: 50%;
    padding-left: 1.3em;
}

.step {
    margin-bottom: 60px;
}

.step.center {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.button {
    display: block;
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
}