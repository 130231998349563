.editable-container a,
.editable-container a:hover{
  text-decoration: none;
  border-bottom: dashed 1px #0088cc;
  cursor: pointer;
  color: #323133;
}
.editable-btn button:nth-child(2) {
  margin-left: 6px;
}
.editable-disabled a,
.editable-disabled a:hover{
  text-decoration: none;
  color: gray;
  cursor: default;
}
.editable-container::after
{
  content: attr(data-after-content);
}

.editable-container::before
{
  content: attr(data-before-content);
}
.btn-group-xs>.btn, .btn-xs {
    padding: 1px 5px !important;
    font-size: 12px  !important;
    line-height: 1.5  !important;;
    border-radius: 3px  !important;
}

.input-sm {
    height: 30px !important;
    padding: 5px 10px !important;
    font-size: 12px !important;
    line-height: 1.5 !important;
    border-radius: 3px !important;
    border-top-left-radius: 3px !important;
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
    border: 1px solid #ccc !important;
}

.popover {
  position: absolute;;
  z-index: 1060 !important;
  max-width: 150px !important;
  padding: 1px !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  letter-spacing: normal !important;
  line-break: auto !important;
  line-height: 1.42857143 !important;
  text-align: start !important;
  text-decoration: none !important;
  text-shadow: none !important;
  text-transform: none !important;
  white-space: normal !important;
  word-break: normal !important;
  word-spacing: normal !important;
  font-size: 14px !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2) !important;
}

.popover.right {
  margin-left: 10px !important;
}
.popover-title {
  margin: 0 !important;
  padding: 8px 14px !important;
  font-size: 14px !important;
  font-weight: 500;
  background-color: #f7f7f7 !important;
  border-bottom: 1px solid #ebebeb !important;
  border-radius: 5px 5px 0 0 !important;
}
.popover-content {
  padding: 9px 14px !important;
}


.popover > .arrow,
.popover > .arrow:after {
  position: absolute !important;
  display: block !important;
  width: 0 !important;
  height: 0 !important;
  border-color: transparent !important;
  border-style: solid !important;
}
.popover > .arrow {
  border-width: 11px !important;
}
.popover > .arrow:after {
  border-width: 10px !important;
  content: "" !important;
}
.popover.top > .arrow {
  left: 50% !important;
  margin-left: -11px !important;
  border-bottom-width: 0 !important;
  border-top-color: #999999 !important;
  border-top-color: rgba(0, 0, 0, 0.25) !important;
  bottom: -11px !important;
}
.popover.top > .arrow:after {
  content: " " !important;
  bottom: 1px !important;
  margin-left: -10px !important;
  border-bottom-width: 0 !important;
  border-top-color: #fff !important;
}
.popover.right > .arrow {
  top: 50% !important;
  left: -11px !important;
  margin-top: -11px !important;
  border-left-width: 0 !important;
  border-right-color: #999999 !important;
  border-right-color: rgba(0, 0, 0, 0.25) !important;
}
.popover.right > .arrow:after {
  content: " " !important;
  left: 1px !important;
  bottom: -10px !important;
  border-left-width: 0 !important;
  border-right-color: #fff !important;
}
.popover.bottom > .arrow {
  left: 50% !important;
  margin-left: -11px !important;
  border-top-width: 0 !important;
  border-bottom-color: #999999 !important;
  border-bottom-color: rgba(0, 0, 0, 0.25) !important;
  top: -11px !important;
}
.popover.bottom > .arrow:after {
  content: " " !important;
  top: 1px !important;
  margin-left: -10px !important;
  border-top-width: 0 !important;
  border-bottom-color: #fff !important;
}
.popover.left > .arrow {
  top: 50% !important;
  right: -11px !important;
  margin-top: -11px !important;
  border-right-width: 0 !important;
  border-left-color: #999999 !important;
  border-left-color: rgba(0, 0, 0, 0.25) !important;
}
.popover.left > .arrow:after {
  content: " " !important;
  right: 1px !important;
  border-right-width: 0 !important;
  border-left-color: #fff !important;
  bottom: -10px !important;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
text-decoration: none;
}
