.grid-container {
  display: grid;
  grid-template-columns: auto;
  padding: 10px;
}

.grid-item {
  font-size: 30px;
  text-align: center;
}

.view-term__header {
  padding: 10px 25px;
}

.view-term__term-name {
  font-size: 34px;
  font-weight: 300;
}

.view-term__term-actions {
  margin-left: 10px;
}

.card-deck{
  padding: 10px;
}

.master-grid-container {
  display: grid;
  grid-template-columns: auto auto;
  padding: 10px;
}

.basic-info-grid-container {
  display: grid;
  grid-template-columns: auto auto;
  padding-right: 50px;
}

.basic-info-grid-item {
  font-size: 12px;
  text-align: left;
  padding-right: 15px;
}

.table-view-contract{
  padding: 10px;
  font-size: 13px;
  text-align: left
}

.basic-item-padding{
  padding-top: 5px;
}

.edit-skus-modal > .modal-content > .modal-body {
  overflow-y: auto !important;
}

.edit-skus-modal > .modal-content > .modal-body > .sku-selector > .sku-selector__section > .sku-selector__section-skusearch,
.edit-skus-modal > .modal-content > .modal-body > .sku-selector > .sku-selector__section > .sku-selector__section-skubin {
  height: 50vh;
  min-height: 300px;
}

/* Data grid header customized styles  */
.pricingTermTable div.rt-th
{
  padding-top: 5px !important;
  padding-bottom: 0px !important;
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.pricingTermTable>.rt-table .rt-th.rt-resizable-header>.rt-resizable-header-content .Select-input
{
  height: 30px;
}

.pricingTermTable>.rt-table .rt-th .form-group .Select--single>.Select-control .Select-value
{
  padding-top: 10px !important;
  padding-bottom: 5px !important;
}

.pricingTermTable>.rt-table .rt-th.rt-resizable-header>.rt-resizable-header-content
{
  overflow:visible !important;
}

.productListing .rt-th, .ReactTable .rt-td
{
  overflow: visible !important;
}

.productListing .rt-tr-group:last-child .rdtOpen .rdtPicker
{
  position: sticky !important;
}