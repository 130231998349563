.contract-home__header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.contract-home__header-title {
  flex: 1 0 0;
  margin: 0;
}

.contract-home__header-btn-container {
  display: flex;
  flex: 0 1 0;
  height: 50px;
}

.contract-header__create-contract-button {
  margin-left: 15px;
  height: 100%;
}

.contract-home__search {
  display: flex;
}

.contract-home__search > .contract-home__search-search-bar {
  flex-grow: 2;
}

.table-cards {
  margin-bottom: 25px;
}

.table-cards .card-holder {
  flex: 1;
  margin-right: 7px;
  margin-left: 7px;
}

.table-cards .card-holder:first-child {
  margin-left: 14px;
}

.table-cards .card-holder:last-child {
  margin-right: 14px;
}

.table-cards .card > .card-block {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.table-cards .card > .card-block > p {
  font-size: 3em;
  color: #F0563A;
}

.loudspeakerAnnouncement__content img {
  max-width: 100%;
  max-height: 100%;
}

.card.clickable:hover {
    background-color: #D4EBF2 !important;
    cursor: pointer;
}

.activeCard {
  background-color: #B0D9E6 !important;
}

.card.not-clickable {
  border: unset;
  box-shadow: unset;
}

.relationship-table{
  background-color: white;
}

.relationship-link{
 padding: 8px;
}
.landing-page{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 10px;
}

.flexContainerWithSpace .search{
  flex: 4;
}

.flexContainerWithSpace .create-contract{
  flex: 2;
  margin-left: 15px;
  margin-bottom: 15px;
}
.home-title-manage-for{
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  flex-wrap: wrap;
}
.contract-home-title {
  flex: 4;
  font-size: 2.5em;
  margin-bottom: 15px;
}

.contract-manager-for{
  flex: 2;
}

.landing-page .relationship{
  flex: 4;
}

.landing-page .contract-card{
  flex: 2;
  margin-left: 30px;
}
.overrideNavTab{
  margin-bottom: 12px !important;
}
.create-contract-button{
 padding-left: 18px !important; 
 padding-right: 18px !important; 
}


