.ReactTable>.rt-table .rt-tr>.rt-td:first-child, .ReactTable>.rt-table .rt-tr>.rt-th:first-child, .ReactTable>.rt-table tr>td:first-child, .ReactTable>.rt-table tr>th:first-child, .table .rt-tr>.rt-td:first-child, .table .rt-tr>.rt-th:first-child, .table tr>td:first-child, .table tr>th:first-child {
    padding-left: 10px !important;
}

.react-table-column-flex-grow-1 {
  flex-grow: 1 !important;
  width: unset !important;
  flex-basis: 5px !important;
  max-width: none !important;
}

.flex-priceLockCell{
  display: flex;
  flex-wrap: wrap;
}

.priceLockDate .datetime .rdt {
  width: 100%;
  flex: 1;
}

.priceLockSaveCancel{
  flex: 1;
  margin-left: 15px;
  margin-top: 5px;
}

.priceLockDelete{
  flex: 1;
  margin-top: 5px;
}