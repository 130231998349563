.option-card {
    min-width: 350px;
    background-color: #ffffff;
    border: 1px solid #3295B5;
    box-shadow: 0 2px 4px 0 rgba(196, 205, 214 ,0.5);
    padding: 40px;
}

.option-card__top {
    /*border-bottom: 1px solid #E1E6EB;*/
}

.option-card__top__top {
    display: flex;
    justify-content: center;
    /*margin-bottom: 40px;*/
}

.option-card__top__top__icon {
    display: flex;
    align-items: center;
    justify-content: left;
    flex-basis: 80px;
    padding-bottom: 30px;
    margin-right: 20px;
}

.option-card__top__top__right {
    display: flex;
    flex-direction: column;
    min-width: 250px;
}

.option-card__top__description {
    color: #8090a2;
    font-size: 1.071em;
    margin-bottom: 60px;
}

.option-card__bottom {
    margin-top: 10px;
    text-align: center;
}
@charset "UTF-8";
/**
 * Mixin to create a keyframes for a notification
 * @param $direction (left|top|right|bottom) Translation direction
 * @param $margin-bottom Initial `margin-bottom` value
 */
/*
 * Mixin to create an animation for a notification
 * @param $direction (left|top|right|bottom) Translation direction
 * @param $margin-bottom Initial `margin-bottom` value
 */
@keyframes _2KrlK6l0Gji64GUfvB0tpO {
  0% {
    top: -360px;
    opacity: 0; }
  100% {
    top: 0;
    opacity: 1; } }

@keyframes bOcvB7WC94ybqMABNyXtD {
  0% {
    transform: translateY(0%);
    opacity: 1;
    max-height: 200px;
    margin-bottom: 20px; }
  40% {
    margin-bottom: 20px;
    max-height: 200px; }
  50% {
    transform: translateY(-100%);
    opacity: 0; }
  100% {
    transform: translateY(-100%);
    opacity: 0;
    max-height: 0;
    margin-bottom: 0; } }

@keyframes _2AxOOVrhkrLvzgdCqYcFf0 {
  0% {
    bottom: -360px;
    opacity: 0; }
  100% {
    bottom: 0;
    opacity: 1; } }

@keyframes _12W4l-sjAp-kGoFnBnva_8 {
  0% {
    transform: translateY(0%);
    opacity: 1;
    max-height: 200px;
    margin-bottom: 20px; }
  40% {
    margin-bottom: 20px;
    max-height: 200px; }
  50% {
    transform: translateY(100%);
    opacity: 0; }
  100% {
    transform: translateY(100%);
    opacity: 0;
    max-height: 0;
    margin-bottom: 0; } }

@keyframes J6HWpcC4pbAMDILcUTY8x {
  0% {
    left: -360px;
    opacity: 0; }
  100% {
    left: 0;
    opacity: 1; } }

@keyframes _1oGClYJuddtJNKMU0vBsIF {
  0% {
    transform: translateX(0%);
    opacity: 1;
    max-height: 200px;
    margin-bottom: 20px; }
  40% {
    margin-bottom: 20px;
    max-height: 200px; }
  50% {
    transform: translateX(-100%);
    opacity: 0; }
  100% {
    transform: translateX(-100%);
    opacity: 0;
    max-height: 0;
    margin-bottom: 0; } }

@keyframes czaWFSocJ9TR5PHBVMEqi {
  0% {
    right: -360px;
    opacity: 0; }
  100% {
    right: 0;
    opacity: 1; } }

@keyframes JenczgqSWdqxYid-9dIAz {
  0% {
    transform: translateX(0%);
    opacity: 1;
    max-height: 200px;
    margin-bottom: 20px; }
  40% {
    margin-bottom: 20px;
    max-height: 200px; }
  50% {
    transform: translateX(100%);
    opacity: 0; }
  100% {
    transform: translateX(100%);
    opacity: 0;
    max-height: 0;
    margin-bottom: 0; } }

@keyframes VA3fn5PUYdNi7_wTWVXKz {
  0% {
    top: -360px;
    opacity: 0; }
  100% {
    top: 0;
    opacity: 1; } }

@keyframes _3GpZDaMOxfHvygfsEKNf0r {
  0% {
    transform: translateY(0%);
    opacity: 1;
    max-height: 200px;
    margin-bottom: 0; }
  40% {
    margin-bottom: 0;
    max-height: 200px; }
  50% {
    transform: translateY(-100%);
    opacity: 0; }
  100% {
    transform: translateY(-100%);
    opacity: 0;
    max-height: 0;
    margin-bottom: 0; } }

@keyframes _1J33rIOpX6rtOhWNJWU82j {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg); }
  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg); } }

._8UxCHC8xndEDp1INe25QF {
  font-size: 14px;
  line-height: 1.428571429; }

._1wTDjQWYCqLQo9jpVTkGnf {
  position: fixed;
  z-index: 99999; }

._3LlNKgH2ISNi90Uk0c8DMY, ._2oKEMEnwVtibTITmDiNZqR, ._2shHZZ--u_vnFP5QNZUoiz, ._1iRCIMVh72Q_LjgRfKpIVK, ._3iieJsNjh-QVrsFYfmXweb, ._1tjZHJVKZ7olnDwH-F9bmI {
  width: 440px; }
  ._3LlNKgH2ISNi90Uk0c8DMY ._2GqAmqmJDiNAhOHrGcEX2c ._3cGPKnBN88fi-OdnVuvOce, ._2oKEMEnwVtibTITmDiNZqR ._2GqAmqmJDiNAhOHrGcEX2c ._3cGPKnBN88fi-OdnVuvOce, ._2shHZZ--u_vnFP5QNZUoiz ._2GqAmqmJDiNAhOHrGcEX2c ._3cGPKnBN88fi-OdnVuvOce, ._1iRCIMVh72Q_LjgRfKpIVK ._2GqAmqmJDiNAhOHrGcEX2c ._3cGPKnBN88fi-OdnVuvOce, ._3iieJsNjh-QVrsFYfmXweb ._2GqAmqmJDiNAhOHrGcEX2c ._3cGPKnBN88fi-OdnVuvOce, ._1tjZHJVKZ7olnDwH-F9bmI ._2GqAmqmJDiNAhOHrGcEX2c ._3cGPKnBN88fi-OdnVuvOce {
    height: 100%; }
  ._3LlNKgH2ISNi90Uk0c8DMY ._2qHFGKiHhVeGusd0rMvRm5 ._3cGPKnBN88fi-OdnVuvOce, ._2oKEMEnwVtibTITmDiNZqR ._2qHFGKiHhVeGusd0rMvRm5 ._3cGPKnBN88fi-OdnVuvOce, ._2shHZZ--u_vnFP5QNZUoiz ._2qHFGKiHhVeGusd0rMvRm5 ._3cGPKnBN88fi-OdnVuvOce, ._1iRCIMVh72Q_LjgRfKpIVK ._2qHFGKiHhVeGusd0rMvRm5 ._3cGPKnBN88fi-OdnVuvOce, ._3iieJsNjh-QVrsFYfmXweb ._2qHFGKiHhVeGusd0rMvRm5 ._3cGPKnBN88fi-OdnVuvOce, ._1tjZHJVKZ7olnDwH-F9bmI ._2qHFGKiHhVeGusd0rMvRm5 ._3cGPKnBN88fi-OdnVuvOce {
    height: 50%; }
  ._3LlNKgH2ISNi90Uk0c8DMY ._2iXT5fFk7pGU3i4gv_EqMJ, ._2oKEMEnwVtibTITmDiNZqR ._2iXT5fFk7pGU3i4gv_EqMJ, ._2shHZZ--u_vnFP5QNZUoiz ._2iXT5fFk7pGU3i4gv_EqMJ, ._1iRCIMVh72Q_LjgRfKpIVK ._2iXT5fFk7pGU3i4gv_EqMJ, ._3iieJsNjh-QVrsFYfmXweb ._2iXT5fFk7pGU3i4gv_EqMJ, ._1tjZHJVKZ7olnDwH-F9bmI ._2iXT5fFk7pGU3i4gv_EqMJ {
    flex-direction: column; }
    ._3LlNKgH2ISNi90Uk0c8DMY ._2iXT5fFk7pGU3i4gv_EqMJ ._3cGPKnBN88fi-OdnVuvOce, ._2oKEMEnwVtibTITmDiNZqR ._2iXT5fFk7pGU3i4gv_EqMJ ._3cGPKnBN88fi-OdnVuvOce, ._2shHZZ--u_vnFP5QNZUoiz ._2iXT5fFk7pGU3i4gv_EqMJ ._3cGPKnBN88fi-OdnVuvOce, ._1iRCIMVh72Q_LjgRfKpIVK ._2iXT5fFk7pGU3i4gv_EqMJ ._3cGPKnBN88fi-OdnVuvOce, ._3iieJsNjh-QVrsFYfmXweb ._2iXT5fFk7pGU3i4gv_EqMJ ._3cGPKnBN88fi-OdnVuvOce, ._1tjZHJVKZ7olnDwH-F9bmI ._2iXT5fFk7pGU3i4gv_EqMJ ._3cGPKnBN88fi-OdnVuvOce {
      display: block;
      width: 100%; }
      ._3LlNKgH2ISNi90Uk0c8DMY ._2iXT5fFk7pGU3i4gv_EqMJ ._3cGPKnBN88fi-OdnVuvOce:nth-child(2), ._2oKEMEnwVtibTITmDiNZqR ._2iXT5fFk7pGU3i4gv_EqMJ ._3cGPKnBN88fi-OdnVuvOce:nth-child(2), ._2shHZZ--u_vnFP5QNZUoiz ._2iXT5fFk7pGU3i4gv_EqMJ ._3cGPKnBN88fi-OdnVuvOce:nth-child(2), ._1iRCIMVh72Q_LjgRfKpIVK ._2iXT5fFk7pGU3i4gv_EqMJ ._3cGPKnBN88fi-OdnVuvOce:nth-child(2), ._3iieJsNjh-QVrsFYfmXweb ._2iXT5fFk7pGU3i4gv_EqMJ ._3cGPKnBN88fi-OdnVuvOce:nth-child(2), ._1tjZHJVKZ7olnDwH-F9bmI ._2iXT5fFk7pGU3i4gv_EqMJ ._3cGPKnBN88fi-OdnVuvOce:nth-child(2) {
        border-top: 1px solid rgba(0, 0, 0, 0.09); }

.MWsxJwbhaRpKe1cHRfNlF ._2iXT5fFk7pGU3i4gv_EqMJ ._2iXT5fFk7pGU3i4gv_EqMJ, .MWsxJwbhaRpKe1cHRfNlF ._2GqAmqmJDiNAhOHrGcEX2c ._2iXT5fFk7pGU3i4gv_EqMJ, ._3WDVskM5g5tQuZVAMMQ2A ._2iXT5fFk7pGU3i4gv_EqMJ ._2iXT5fFk7pGU3i4gv_EqMJ, ._3WDVskM5g5tQuZVAMMQ2A ._2GqAmqmJDiNAhOHrGcEX2c ._2iXT5fFk7pGU3i4gv_EqMJ {
  flex-direction: row; }

._3WDVskM5g5tQuZVAMMQ2A {
  width: calc(100% - 40px);
  top: 20px;
  left: 20px; }
  ._3WDVskM5g5tQuZVAMMQ2A ._3m-VNUU5JvTgnr5nlP6a_W {
    position: relative;
    top: 0; }
    ._3WDVskM5g5tQuZVAMMQ2A ._3OYQjyyDifYobo0nueW5DL {
      animation-fill-mode: forwards;
      animation-name: _2KrlK6l0Gji64GUfvB0tpO;
      animation-duration: .4s; }
    ._3WDVskM5g5tQuZVAMMQ2A ._3K3OuEkDYtS0btJVH5b78A {
      animation-fill-mode: forwards;
      animation-name: bOcvB7WC94ybqMABNyXtD;
      animation-duration: .8s; }

._3LlNKgH2ISNi90Uk0c8DMY {
  top: 20px;
  left: 50%;
  transform: translateX(-50%); }
  ._3LlNKgH2ISNi90Uk0c8DMY ._3m-VNUU5JvTgnr5nlP6a_W {
    position: relative;
    top: 0; }
    ._3LlNKgH2ISNi90Uk0c8DMY ._3OYQjyyDifYobo0nueW5DL {
      animation-fill-mode: forwards;
      animation-name: _2KrlK6l0Gji64GUfvB0tpO;
      animation-duration: .4s; }
    ._3LlNKgH2ISNi90Uk0c8DMY ._3K3OuEkDYtS0btJVH5b78A {
      animation-fill-mode: forwards;
      animation-name: bOcvB7WC94ybqMABNyXtD;
      animation-duration: .8s; }

._2oKEMEnwVtibTITmDiNZqR {
  top: 20px;
  right: 20px; }
  ._2oKEMEnwVtibTITmDiNZqR ._3m-VNUU5JvTgnr5nlP6a_W {
    position: relative;
    right: 0; }
    ._2oKEMEnwVtibTITmDiNZqR ._3OYQjyyDifYobo0nueW5DL {
      animation-fill-mode: forwards;
      animation-name: czaWFSocJ9TR5PHBVMEqi;
      animation-duration: .4s; }
    ._2oKEMEnwVtibTITmDiNZqR ._3K3OuEkDYtS0btJVH5b78A {
      animation-fill-mode: forwards;
      animation-name: JenczgqSWdqxYid-9dIAz;
      animation-duration: .8s; }

._2shHZZ--u_vnFP5QNZUoiz {
  top: 20px;
  left: 20px; }
  ._2shHZZ--u_vnFP5QNZUoiz ._3m-VNUU5JvTgnr5nlP6a_W {
    position: relative;
    left: 0; }
    ._2shHZZ--u_vnFP5QNZUoiz ._3OYQjyyDifYobo0nueW5DL {
      animation-fill-mode: forwards;
      animation-name: J6HWpcC4pbAMDILcUTY8x;
      animation-duration: .4s; }
    ._2shHZZ--u_vnFP5QNZUoiz ._3K3OuEkDYtS0btJVH5b78A {
      animation-fill-mode: forwards;
      animation-name: _1oGClYJuddtJNKMU0vBsIF;
      animation-duration: .8s; }

.MWsxJwbhaRpKe1cHRfNlF {
  width: calc(100% - 40px);
  bottom: 0;
  left: 20px; }
  .MWsxJwbhaRpKe1cHRfNlF ._3m-VNUU5JvTgnr5nlP6a_W {
    position: relative;
    bottom: 0; }
    .MWsxJwbhaRpKe1cHRfNlF ._3OYQjyyDifYobo0nueW5DL {
      animation-fill-mode: forwards;
      animation-name: _2AxOOVrhkrLvzgdCqYcFf0;
      animation-duration: .4s; }
    .MWsxJwbhaRpKe1cHRfNlF ._3K3OuEkDYtS0btJVH5b78A {
      animation-fill-mode: forwards;
      animation-name: _12W4l-sjAp-kGoFnBnva_8;
      animation-duration: .8s; }

._1iRCIMVh72Q_LjgRfKpIVK {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%); }
  ._1iRCIMVh72Q_LjgRfKpIVK ._3m-VNUU5JvTgnr5nlP6a_W {
    position: relative;
    bottom: 0; }
    ._1iRCIMVh72Q_LjgRfKpIVK ._3OYQjyyDifYobo0nueW5DL {
      animation-fill-mode: forwards;
      animation-name: _2AxOOVrhkrLvzgdCqYcFf0;
      animation-duration: .4s; }
    ._1iRCIMVh72Q_LjgRfKpIVK ._3K3OuEkDYtS0btJVH5b78A {
      animation-fill-mode: forwards;
      animation-name: _12W4l-sjAp-kGoFnBnva_8;
      animation-duration: .8s; }

._3iieJsNjh-QVrsFYfmXweb {
  bottom: 0;
  right: 20px; }
  ._3iieJsNjh-QVrsFYfmXweb ._3m-VNUU5JvTgnr5nlP6a_W {
    position: relative;
    right: 0; }
    ._3iieJsNjh-QVrsFYfmXweb ._3OYQjyyDifYobo0nueW5DL {
      animation-fill-mode: forwards;
      animation-name: czaWFSocJ9TR5PHBVMEqi;
      animation-duration: .4s; }
    ._3iieJsNjh-QVrsFYfmXweb ._3K3OuEkDYtS0btJVH5b78A {
      animation-fill-mode: forwards;
      animation-name: JenczgqSWdqxYid-9dIAz;
      animation-duration: .8s; }

._1tjZHJVKZ7olnDwH-F9bmI {
  bottom: 0;
  left: 20px; }
  ._1tjZHJVKZ7olnDwH-F9bmI ._3m-VNUU5JvTgnr5nlP6a_W {
    position: relative;
    left: 0; }
    ._1tjZHJVKZ7olnDwH-F9bmI ._3OYQjyyDifYobo0nueW5DL {
      animation-fill-mode: forwards;
      animation-name: J6HWpcC4pbAMDILcUTY8x;
      animation-duration: .4s; }
    ._1tjZHJVKZ7olnDwH-F9bmI ._3K3OuEkDYtS0btJVH5b78A {
      animation-fill-mode: forwards;
      animation-name: _1oGClYJuddtJNKMU0vBsIF;
      animation-duration: .8s; }

@media (max-width: 767px) {
  ._3WDVskM5g5tQuZVAMMQ2A {
    width: 100%;
    top: 0;
    left: 0; }
    ._3WDVskM5g5tQuZVAMMQ2A ._3m-VNUU5JvTgnr5nlP6a_W {
      position: relative;
      top: 0; }
      ._3WDVskM5g5tQuZVAMMQ2A ._3OYQjyyDifYobo0nueW5DL {
        animation-fill-mode: forwards;
        animation-name: VA3fn5PUYdNi7_wTWVXKz;
        animation-duration: .4s; }
      ._3WDVskM5g5tQuZVAMMQ2A ._3K3OuEkDYtS0btJVH5b78A {
        animation-fill-mode: forwards;
        animation-name: _3GpZDaMOxfHvygfsEKNf0r;
        animation-duration: .8s; }
    ._3WDVskM5g5tQuZVAMMQ2A ._3m-VNUU5JvTgnr5nlP6a_W {
      margin: 0; }
  ._14iPYrvMMuXi_jYiuq5jiQ {
    left: -18px !important; } }

.LwNjHlDtK6disWfUxpu7e {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 40px;
  border: none;
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.3);
  z-index: 999; }
  ._3m-VNUU5JvTgnr5nlP6a_W {
    display: block;
    width: 100%;
    height: 100%;
    margin-bottom: 20px; }
  .MCMySU6AFU_x_rEDKuZqF {
    box-sizing: border-box;
    padding: 10px 0 10px 15px; }
  .owd5PlD1pwnwd608pzW-I {
    display: inline-block;
    border-radius: 40px;
    width: 40px;
    height: 40px;
    background-size: cover; }
  ._14iPYrvMMuXi_jYiuq5jiQ {
    box-sizing: border-box;
    left: -27px;
    position: absolute;
    top: 12px;
    margin: 0;
    align-self: flex-start; }
  ._3F5Kt1tROf646IwSDkEGAx {
    vertical-align: top;
    box-sizing: border-box;
    width: 100%;
    padding: 10px 20px; }
  ._1BYdu8zGduUlOfZD3LfBxq {
    padding: 10px 15px;
    box-sizing: border-box; }
  ._2BSk4R-3q4zyn-nTKp5Ixd {
    font-size: 14px !important;
    color: #c4cdd6;
    cursor: pointer; }
    ._2BSk4R-3q4zyn-nTKp5Ixd:before {
      content: "\F00D"; }
    ._2BSk4R-3q4zyn-nTKp5Ixd:hover {
      color: #6c6565; }
  ._2iXT5fFk7pGU3i4gv_EqMJ {
    display: flex;
    box-sizing: border-box;
    vertical-align: top; }
    ._2iXT5fFk7pGU3i4gv_EqMJ ._3cGPKnBN88fi-OdnVuvOce {
      min-height: 40px;
      box-sizing: border-box;
      padding: 0;
      background: none;
      border: none;
      border-radius: 0;
      border-left: 1px solid rgba(0, 0, 0, 0.09);
      outline: none;
      text-align: center;
      color: #0088a9;
      cursor: pointer; }
      ._2iXT5fFk7pGU3i4gv_EqMJ ._1tr4LWcpRQvxZIduphYBCN {
        display: block;
        height: 25px;
        padding: 0 15px;
        min-width: 90px;
        max-width: 150px;
        width: auto;
        white-space: nowrap;
        overflow: hidden;
        margin: 0 auto;
        text-overflow: ellipsis;
        text-align: center;
        font-size: 14px;
        line-height: 25px; }
      ._2iXT5fFk7pGU3i4gv_EqMJ ._3cGPKnBN88fi-OdnVuvOce:hover {
        background: #CEE8F0;
        color: #00738E; }
      ._2iXT5fFk7pGU3i4gv_EqMJ ._3cGPKnBN88fi-OdnVuvOce:active {
        background: #A7D4E2;
        color: #005C75; }
  ._2hmHc4LGfKs-tAMLWb3Ty- {
    margin: 0 0 10px;
    font-size: 15px;
    font-weight: 700;
    line-height: 1.428571429; }
  ._2nWkoJA99DzuaI5KPPeIev {
    word-break: break-word;
    margin: 0; }
  ._3onDrzQYEeDzAUdEiFiysR {
    cursor: pointer; }
  .koAqz4N5xdmyWgGVIf5WW {
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-left: 40px solid #ffffff;
    color: #524c4c; }
    @media (max-width: 767px) {
      .koAqz4N5xdmyWgGVIf5WW {
        border-left: 24px solid #ffffff; } }
    .koAqz4N5xdmyWgGVIf5WW {
      border-left: 0; }
    .koAqz4N5xdmyWgGVIf5WW ._14iPYrvMMuXi_jYiuq5jiQ {
      display: none; }
  ._2V72tHX8_-WpA-y1blxvxt {
    background-color: #ffffff;
    border: 1px solid #35ad96;
    border-left: 40px solid #35ad96;
    color: #524c4c; }
    @media (max-width: 767px) {
      ._2V72tHX8_-WpA-y1blxvxt {
        border-left: 24px solid #35ad96; } }
    ._2V72tHX8_-WpA-y1blxvxt ._14iPYrvMMuXi_jYiuq5jiQ:before {
      content: "\F00C";
      color: #fff; }
  ._3KMXj-fnOeZQh6cl4Pxzi5 {
    background-color: #ffffff;
    border: 1px solid #41b5dc;
    border-left: 40px solid #41b5dc;
    color: #524c4c; }
    @media (max-width: 767px) {
      ._3KMXj-fnOeZQh6cl4Pxzi5 {
        border-left: 24px solid #41b5dc; } }
    ._3KMXj-fnOeZQh6cl4Pxzi5 ._14iPYrvMMuXi_jYiuq5jiQ:before {
      content: "\F05A";
      color: #fff; }
  ._3acB7y6Z4NCM6ByYiAs1mA {
    background-color: #ffffff;
    border: 1px solid #ffa23f;
    border-left: 40px solid #ffa23f;
    color: #524c4c; }
    @media (max-width: 767px) {
      ._3acB7y6Z4NCM6ByYiAs1mA {
        border-left: 24px solid #ffa23f; } }
    ._3acB7y6Z4NCM6ByYiAs1mA ._14iPYrvMMuXi_jYiuq5jiQ:before {
      content: "\F071";
      color: #fff; }
  ._2n5iWphCzhaq3WBz11lrjR {
    background-color: #ffffff;
    border: 1px solid #d24a35;
    border-left: 40px solid #d24a35;
    color: #524c4c; }
    @media (max-width: 767px) {
      ._2n5iWphCzhaq3WBz11lrjR {
        border-left: 24px solid #d24a35; } }
    ._2n5iWphCzhaq3WBz11lrjR ._14iPYrvMMuXi_jYiuq5jiQ:before {
      content: "\F057";
      color: #fff; }
  .hPBTIUdn5gobeLRLgqXMk {
    background-color: #ffffff;
    border: 1px solid #41b5dc;
    border-left: 40px solid #41b5dc;
    color: #524c4c; }
    .hPBTIUdn5gobeLRLgqXMk ._14iPYrvMMuXi_jYiuq5jiQ {
      animation: _1J33rIOpX6rtOhWNJWU82j 2s infinite linear; }
    @media (max-width: 767px) {
      .hPBTIUdn5gobeLRLgqXMk {
        border-left: 24px solid #41b5dc; } }
    .hPBTIUdn5gobeLRLgqXMk ._14iPYrvMMuXi_jYiuq5jiQ:before {
      content: "\F110";
      color: #fff; }

.editable-container a,
.editable-container a:hover{
  text-decoration: none;
  border-bottom: dashed 1px #0088cc;
  cursor: pointer;
  color: #323133;
}
.editable-btn button:nth-child(2) {
  margin-left: 6px;
}
.editable-disabled a,
.editable-disabled a:hover{
  text-decoration: none;
  color: gray;
  cursor: default;
}
.editable-container::after
{
  content: attr(data-after-content);
}

.editable-container::before
{
  content: attr(data-before-content);
}
.btn-group-xs>.btn, .btn-xs {
    padding: 1px 5px !important;
    font-size: 12px  !important;
    line-height: 1.5  !important;;
    border-radius: 3px  !important;
}

.input-sm {
    height: 30px !important;
    padding: 5px 10px !important;
    font-size: 12px !important;
    line-height: 1.5 !important;
    border-radius: 3px !important;
    border-top-left-radius: 3px !important;
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
    border: 1px solid #ccc !important;
}

.popover {
  position: absolute;;
  z-index: 1060 !important;
  max-width: 150px !important;
  padding: 1px !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  letter-spacing: normal !important;
  line-break: auto !important;
  line-height: 1.42857143 !important;
  text-align: start !important;
  text-decoration: none !important;
  text-shadow: none !important;
  text-transform: none !important;
  white-space: normal !important;
  word-break: normal !important;
  word-spacing: normal !important;
  font-size: 14px !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2) !important;
}

.popover.right {
  margin-left: 10px !important;
}
.popover-title {
  margin: 0 !important;
  padding: 8px 14px !important;
  font-size: 14px !important;
  font-weight: 500;
  background-color: #f7f7f7 !important;
  border-bottom: 1px solid #ebebeb !important;
  border-radius: 5px 5px 0 0 !important;
}
.popover-content {
  padding: 9px 14px !important;
}


.popover > .arrow,
.popover > .arrow:after {
  position: absolute !important;
  display: block !important;
  width: 0 !important;
  height: 0 !important;
  border-color: transparent !important;
  border-style: solid !important;
}
.popover > .arrow {
  border-width: 11px !important;
}
.popover > .arrow:after {
  border-width: 10px !important;
  content: "" !important;
}
.popover.top > .arrow {
  left: 50% !important;
  margin-left: -11px !important;
  border-bottom-width: 0 !important;
  border-top-color: #999999 !important;
  border-top-color: rgba(0, 0, 0, 0.25) !important;
  bottom: -11px !important;
}
.popover.top > .arrow:after {
  content: " " !important;
  bottom: 1px !important;
  margin-left: -10px !important;
  border-bottom-width: 0 !important;
  border-top-color: #fff !important;
}
.popover.right > .arrow {
  top: 50% !important;
  left: -11px !important;
  margin-top: -11px !important;
  border-left-width: 0 !important;
  border-right-color: #999999 !important;
  border-right-color: rgba(0, 0, 0, 0.25) !important;
}
.popover.right > .arrow:after {
  content: " " !important;
  left: 1px !important;
  bottom: -10px !important;
  border-left-width: 0 !important;
  border-right-color: #fff !important;
}
.popover.bottom > .arrow {
  left: 50% !important;
  margin-left: -11px !important;
  border-top-width: 0 !important;
  border-bottom-color: #999999 !important;
  border-bottom-color: rgba(0, 0, 0, 0.25) !important;
  top: -11px !important;
}
.popover.bottom > .arrow:after {
  content: " " !important;
  top: 1px !important;
  margin-left: -10px !important;
  border-top-width: 0 !important;
  border-bottom-color: #fff !important;
}
.popover.left > .arrow {
  top: 50% !important;
  right: -11px !important;
  margin-top: -11px !important;
  border-right-width: 0 !important;
  border-left-color: #999999 !important;
  border-left-color: rgba(0, 0, 0, 0.25) !important;
}
.popover.left > .arrow:after {
  content: " " !important;
  right: 1px !important;
  border-right-width: 0 !important;
  border-left-color: #fff !important;
  bottom: -10px !important;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
text-decoration: none;
}

.main {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main__content {
    flex: 1 1;
    width: 100%;
}

.error-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.navtab-container {
    display: flex;
    margin-bottom: -47px;
}

.loading-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.flex-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    border-bottom: 1px solid #F0563A;
}

.flex-container.success {
    border-bottom: 1px solid #35AD96;
}

.row1 {
    display: flex;
    flex-direction: row;
    padding: 10px 60px 20px 60px;
}

.row1 > aside {
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
}

.row1 .icon {
    justify-content: flex-end;
    align-self: center;
}

.middle {
}

.row1 .middle {
    display: flex;
    flex-direction: column;
    max-width: 70%;
}

.row1 .middle .title {
    margin-bottom: 10px;
}

.row1 .middle .description {
    color: #8090A2; /* slate */
}

.row2 {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    padding: 0 60px 30px 60px;
}

.row2 .middle {
    display: flex;
    justify-content: center;
    background-color: #EDF3F9; /* Horizon */
    padding: 10px 25px;
    flex-grow: 1;
}

.modal-overflow > .modal-content > .modal-body {
    overflow-y: visible;
    overflow-y: initial;
}

.create-contract-modal > .modal-content {
  height: 100%;
}


.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

 .flexContainer  {
   display: flex;
   flex-direction: row;
   align-items: center;
   flex-wrap: nowrap;

}

.flexContainerWithSpace  {
 display: flex;
 flex-direction: row;
 align-items: center;
 width: 100%;
 justify-content:space-between;
}
.titleBoldStyle {
  font-weight:800;
  font-size: 20px;
  padding-bottom: 5px
}

.titleSubHeaderStyle  {
  font-weight: 700;
  font-size:18px
}

.partyHeaderStyle  {
  font-size: 15px;
  margin-bottom:  15px;
  margin-top: 0;
  text-align: left
};

.disable-control
{
  pointer-events: none;
  opacity: 0.7;
}

.has-search .form-control-feedback {
    position: absolute!important;
    z-index: 2!important;
    display: block!important;
    line-height: 3.5!important;
    text-align: center!important;
    pointer-events: none;
    color: #aaa;
}
.item1 {
  grid-area: cell1;
}
.item2 {
  grid-area: cell2;
}
.item3 {
  grid-area: cell3;
}

.flexend
{
  align-self: flex-end;
}

.sku-selector__section {
    margin-bottom: 5px;
  }

  .sku-selector__section-heading {
    text-align: center;
    margin-bottom: 5px;
  }

  .sku-selector__section-skusearch, .sku-selector__section-skubin{
    min-height: 480px;
    min-width: 300px;
  }
.grid-container {
  display: grid;
  grid-template-areas: 'cell1 cell1 cell1 cell1' 'cell2 cell2 cell2 cell2' 'cell3 cell3 cell3 cell3' ;
  grid-gap: 10px;

  padding: 10px;
}

.grid-container1 {
  display: grid;
  grid-template-columns: 75% 25%;
  grid-gap: 10px;
  padding: 10px;
}
.item4 {
  grid-area: cell4;
}

.flex-containerImage {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-header-title-with-icon{
  margin-left: 10px;
  margin-bottom: 5px;
}

.pagetitle
{
  font-weight: 700px!important;

  padding-bottom: 10px
}

.card-header{
  font-size: 14px;
  font-weight: bold;
}

.box
{
  font-size: 13px
}

#grid {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

#areaA {
align-items:baseline;
}


#areaB {
justify-self: end;

}

.notificationBackground
{
  padding-top:10px;
  padding-bottom: 30px;
  background-color: white;
  padding-left: 30px;
  padding-right: 30px
}

.overrideNotificationNavTab
{
  margin-bottom: 5px !important;
}

.hrNotificationDetail
{
  border-width:0;
  color:gray;
  background-color:gray;
  height:1px;
}

.hrCreateContractPopUp
{
  border-width:0;
  color:gray;
  background-color:gray;
  height:1px;
}

.hrNotificationDetailPriceChange
{
  border-width:0;
  color:lightgray;
  background-color:lightgray;
  height:1px;
  margin-top: 5px;
}

.notificationDetailBackground
{
  padding-top:30px;
  padding-bottom: 30px;
  background-color: white;
  padding-left: 30px;
  padding-right: 30px
}

.subscriptionBackground
{
  padding: 35px;
  background-color: white;
}
.titlePadding
{
  padding-left: 50px;
}

.breadCrumbPadding
{
  padding-left:35px;
}

.tabMinHeight
{
  min-height: 200px !important;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden!important;
  text-overflow: ellipsis;
}
.react-datepicker__week
{
  font-size: 12px;
}
.react-datepicker__day
{
  margin-left: 1px !important;
  margin-right: 6px !important;
}
.react-datepicker__header
{
  font-size: 13px !important;
}
.react-datepicker__current-month
{
  font-size: 13px !important;

}
.react-datepicker__month-container
{
  width: 180px !important;
}
.react-datepicker__day-name
{
  margin: 4px !important;
}

input#fromdate { width: 100px; }

.ReactTable>.rt-table .rt-tr>.rt-td:first-child, .ReactTable>.rt-table .rt-tr>.rt-th:first-child, .ReactTable>.rt-table tr>td:first-child, .ReactTable>.rt-table tr>th:first-child, .table .rt-tr>.rt-td:first-child, .table .rt-tr>.rt-th:first-child, .table tr>td:first-child, .table tr>th:first-child {
    padding-left: 10px !important;
}

.react-table-column-flex-grow-1 {
  flex-grow: 1 !important;
  width: unset !important;
  flex-basis: 5px !important;
  max-width: none !important;
}

.flex-priceLockCell{
  display: flex;
  flex-wrap: wrap;
}

.priceLockDate .datetime .rdt {
  width: 100%;
  flex: 1 1;
}

.priceLockSaveCancel{
  flex: 1 1;
  margin-left: 15px;
  margin-top: 5px;
}

.priceLockDelete{
  flex: 1 1;
  margin-top: 5px;
}
.grid-container {
  display: grid;
  grid-template-columns: auto;
  padding: 10px;
}

.grid-item {
  font-size: 30px;
  text-align: center;
}

.view-term__header {
  padding: 10px 25px;
}

.view-term__term-name {
  font-size: 34px;
  font-weight: 300;
}

.view-term__term-actions {
  margin-left: 10px;
}

.card-deck{
  padding: 10px;
}

.master-grid-container {
  display: grid;
  grid-template-columns: auto auto;
  padding: 10px;
}

.basic-info-grid-container {
  display: grid;
  grid-template-columns: auto auto;
  padding-right: 50px;
}

.basic-info-grid-item {
  font-size: 12px;
  text-align: left;
  padding-right: 15px;
}

.table-view-contract{
  padding: 10px;
  font-size: 13px;
  text-align: left
}

.basic-item-padding{
  padding-top: 5px;
}

.edit-skus-modal > .modal-content > .modal-body {
  overflow-y: auto !important;
}

.edit-skus-modal > .modal-content > .modal-body > .sku-selector > .sku-selector__section > .sku-selector__section-skusearch,
.edit-skus-modal > .modal-content > .modal-body > .sku-selector > .sku-selector__section > .sku-selector__section-skubin {
  height: 50vh;
  min-height: 300px;
}

/* Data grid header customized styles  */
.pricingTermTable div.rt-th
{
  padding-top: 5px !important;
  padding-bottom: 0px !important;
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.pricingTermTable>.rt-table .rt-th.rt-resizable-header>.rt-resizable-header-content .Select-input
{
  height: 30px;
}

.pricingTermTable>.rt-table .rt-th .form-group .Select--single>.Select-control .Select-value
{
  padding-top: 10px !important;
  padding-bottom: 5px !important;
}

.pricingTermTable>.rt-table .rt-th.rt-resizable-header>.rt-resizable-header-content
{
  overflow:visible !important;
}

.productListing .rt-th, .ReactTable .rt-td
{
  overflow: visible !important;
}

.productListing .rt-tr-group:last-child .rdtOpen .rdtPicker
{
  position: -webkit-sticky !important;
  position: sticky !important;
}
.sku-card {
    height: 60px;
    display:flex !important;
    padding: 4px 4px 4px 0;
    border: 1px solid #c4cdd6;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(196, 205, 214, 0.5);
    transition: opacity 1s ease-in-out;
    visibility: visible !important;
}

.sku-card.disabled {
    background-color: #EFF3F6;
    color: #c4cdd6;
    cursor: not-allowed;
}

.sku-card__info {
    flex-grow: 1;
    margin: 0 10px;
    align-self: center;
    min-width: 0;
    flex-basis: 100px;
}

.sku-card__info__top {
    font-weight: 500;
    font-size: 15px;
}

.sku-card__info__top, .sku-card__info__bottom {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.sku-card__info__top__name::before {
    content: ' | ';
}

.sku-card__info__bottom {
    margin-top: 9px;
}

.sku-card__img {
    text-align: center;
    flex-basis: 50px;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    background-color: #e1e6eb;
}

.sku-card__shape-container {
    padding-top: 4px;
    line-height: 50px;
}


.sku-card__drag {
    flex-shrink: 0;
    align-self: center;
    text-align: center;
    cursor: move; /* fallback if grab cursor is unspported */
    cursor: grab;
    cursor: -webkit-grab;
    pointer-events: auto;
}

.sku-card__drag:active {
    cursor: grabbing;
    cursor: -webkit-grabbing;
}

.sku-card__pre-action {
    flex-basis: 50px;
    flex-shrink: 0;
    align-self: center;
    text-align: center;
    cursor: pointer;
    pointer-events: auto;
}

.sku-card__pre-action__checkbox {
    padding-left: 10px; /* cancel out Mex component's padding on checkboxes */
}

.sku-card__post-action {
    flex-basis: 50px;
    flex-shrink: 0;
    align-self: center;
    text-align: center;
    pointer-events: auto;
}

.sku-card__post-action__link--disabled .injected-svg {
    fill: #35AD96;
}

.sku-card__post-action__link--disabled:hover .injected-svg {
    fill: #35AD96;
}

.sku-card__post-action__link--clickable {
    cursor: pointer;
}

.sku-card__post-action__link--clickable:hover .injected-svg {
    fill: #a7d4e2;
}

.sku-card__post-action__link--clickable:active .injected-svg {
    fill: #87b9c9;
}
.main-heading-style-notification-details {
    font-size: 26px;
    align-items: center;
    font-weight: 800;
    padding: 15px;
}

.sub-heading-style-notification-details {
    font-size: 16px;
    align-items: center;
    font-weight: 600;
    padding: 15px;
}

.content-style-notification-details {
    font-size: 14px;
    align-items: center;
    font-weight: 550;
    padding: 15px;
}

.acknowledge-button-style-notification-details {
    padding-left: 45%;
}

.contract-home__header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.contract-home__header-title {
  flex: 1 0;
  margin: 0;
}

.contract-home__header-btn-container {
  display: flex;
  flex: 0 1;
  height: 50px;
}

.contract-header__create-contract-button {
  margin-left: 15px;
  height: 100%;
}

.contract-home__search {
  display: flex;
}

.contract-home__search > .contract-home__search-search-bar {
  flex-grow: 2;
}

.table-cards {
  margin-bottom: 25px;
}

.table-cards .card-holder {
  flex: 1 1;
  margin-right: 7px;
  margin-left: 7px;
}

.table-cards .card-holder:first-child {
  margin-left: 14px;
}

.table-cards .card-holder:last-child {
  margin-right: 14px;
}

.table-cards .card > .card-block {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.table-cards .card > .card-block > p {
  font-size: 3em;
  color: #F0563A;
}

.loudspeakerAnnouncement__content img {
  max-width: 100%;
  max-height: 100%;
}

.card.clickable:hover {
    background-color: #D4EBF2 !important;
    cursor: pointer;
}

.activeCard {
  background-color: #B0D9E6 !important;
}

.card.not-clickable {
  border: unset;
  box-shadow: unset;
}

.relationship-table{
  background-color: white;
}

.relationship-link{
 padding: 8px;
}
.landing-page{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 10px;
}

.flexContainerWithSpace .search{
  flex: 4 1;
}

.flexContainerWithSpace .create-contract{
  flex: 2 1;
  margin-left: 15px;
  margin-bottom: 15px;
}
.home-title-manage-for{
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  flex-wrap: wrap;
}
.contract-home-title {
  flex: 4 1;
  font-size: 2.5em;
  margin-bottom: 15px;
}

.contract-manager-for{
  flex: 2 1;
}

.landing-page .relationship{
  flex: 4 1;
}

.landing-page .contract-card{
  flex: 2 1;
  margin-left: 30px;
}
.overrideNavTab{
  margin-bottom: 12px !important;
}
.create-contract-button{
 padding-left: 18px !important; 
 padding-right: 18px !important; 
}




.settings__identity-button {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
}

.settings__subscriptions-icon {
    margin-top: 17px;
    z-index: 1;
    margin-left: -30px;
}

.settings__auto-approval-icon {
    margin-top: 37px;
    padding-right: 35px;
    z-index: 1;
}

.settings__search-header {
    display: flex;
    justify-content: flex-end;
}

.settings__button-group {
    min-width: -webkit-min-content;
    min-width: min-content;
}

.toggle {
   width: unset !important;
}

.toggle-label {
   width: unset !important;
}

.flex-filterNotification{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .notificationDate .datetime .rdt {
    width: 180px;
  }
  
  .startDateFlexBox{
    flex: 1 1;
  }
  .endDateFlexBox{
    flex: 1 1;
    margin-left: 15px;
  }
  .productIdsFlexBox{
    flex: 6 1;
    margin-left: 15px;
  }
.card-landing-body{
    flex: 0 0 30%;
}
.card-landing-header{
    display: flex;
    align-items: center;
    font-size: 12px;
    height: 1.4em;
}

.card-bottom-button{
    margin-top: 15px;
}


.card-header-title-with-icon{
    margin-left: 10px;
}
  
.cardStyle{
    display: flex;
    min-height: 135px;
    padding: 5px;
    border: 2px solid #e1e6eb
}

.imageBox{
    flex: 1 1;
    padding: 15px;
}

.messageBox{
    flex: 6 1;
    padding: 10px;
}

.innerFlexBox{
    display: flex;
    flex-direction: column;
}
  
.lockedUntilDate{
    width: 100px;
    flex: 1 1;
}
.key-value-list {
    margin: 0 auto 9px auto;
    list-style: none;
    display: table;
}

.key-value-list__row {
    display: table-row;
    border-bottom: 1px solid #E8E8E8;
}

.key-value-list__key {
    display: table-cell;
    width: 50%;
    font-weight: 700;
    text-align: right;
}

.key-value-list__key::after {
    content: ": ";
}

.key-value-list__value {
    display: table-cell;
    width: 50%;
    padding-left: 1.3em;
}

.step {
    margin-bottom: 60px;
}

.step.center {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.button {
    display: block;
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
}
