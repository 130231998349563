.main {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main__content {
    flex: 1;
    width: 100%;
}

.error-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.navtab-container {
    display: flex;
    margin-bottom: -47px;
}

.loading-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.flex-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    border-bottom: 1px solid #F0563A;
}

.flex-container.success {
    border-bottom: 1px solid #35AD96;
}

.row1 {
    display: flex;
    flex-direction: row;
    padding: 10px 60px 20px 60px;
}

.row1 > aside {
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
}

.row1 .icon {
    justify-content: flex-end;
    align-self: center;
}

.middle {
}

.row1 .middle {
    display: flex;
    flex-direction: column;
    max-width: 70%;
}

.row1 .middle .title {
    margin-bottom: 10px;
}

.row1 .middle .description {
    color: #8090A2; /* slate */
}

.row2 {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 0 60px 30px 60px;
}

.row2 .middle {
    display: flex;
    justify-content: center;
    background-color: #EDF3F9; /* Horizon */
    padding: 10px 25px;
    flex-grow: 1;
}

.modal-overflow > .modal-content > .modal-body {
    overflow-y: initial;
}

.create-contract-modal > .modal-content {
  height: 100%;
}


.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

 .flexContainer  {
   display: flex;
   flex-direction: row;
   align-items: center;
   flex-wrap: nowrap;

}

.flexContainerWithSpace  {
 display: flex;
 flex-direction: row;
 align-items: center;
 width: 100%;
 justify-content:space-between;
}
.titleBoldStyle {
  font-weight:800;
  font-size: 20px;
  padding-bottom: 5px
}

.titleSubHeaderStyle  {
  font-weight: 700;
  font-size:18px
}

.partyHeaderStyle  {
  font-size: 15px;
  margin-bottom:  15px;
  margin-top: 0;
  text-align: left
};

.disable-control
{
  pointer-events: none;
  opacity: 0.7;
}

.has-search .form-control-feedback {
    position: absolute!important;
    z-index: 2!important;
    display: block!important;
    line-height: 3.5!important;
    text-align: center!important;
    pointer-events: none;
    color: #aaa;
}
.item1 {
  grid-area: cell1;
}
.item2 {
  grid-area: cell2;
}
.item3 {
  grid-area: cell3;
}

.flexend
{
  align-self: flex-end;
}

.sku-selector__section {
    margin-bottom: 5px;
  }

  .sku-selector__section-heading {
    text-align: center;
    margin-bottom: 5px;
  }

  .sku-selector__section-skusearch, .sku-selector__section-skubin{
    min-height: 480px;
    min-width: 300px;
  }
.grid-container {
  display: grid;
  grid-template-areas: 'cell1 cell1 cell1 cell1' 'cell2 cell2 cell2 cell2' 'cell3 cell3 cell3 cell3' ;
  grid-gap: 10px;

  padding: 10px;
}

.grid-container1 {
  display: grid;
  grid-template-columns: 75% 25%;
  grid-gap: 10px;
  padding: 10px;
}
.item4 {
  grid-area: cell4;
}

.flex-containerImage {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-header-title-with-icon{
  margin-left: 10px;
  margin-bottom: 5px;
}

.pagetitle
{
  font-weight: 700px!important;

  padding-bottom: 10px
}

.card-header{
  font-size: 14px;
  font-weight: bold;
}

.box
{
  font-size: 13px
}

#grid {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

#areaA {
align-items:baseline;
}


#areaB {
justify-self: end;

}

.notificationBackground
{
  padding-top:10px;
  padding-bottom: 30px;
  background-color: white;
  padding-left: 30px;
  padding-right: 30px
}

.overrideNotificationNavTab
{
  margin-bottom: 5px !important;
}

.hrNotificationDetail
{
  border-width:0;
  color:gray;
  background-color:gray;
  height:1px;
}

.hrCreateContractPopUp
{
  border-width:0;
  color:gray;
  background-color:gray;
  height:1px;
}

.hrNotificationDetailPriceChange
{
  border-width:0;
  color:lightgray;
  background-color:lightgray;
  height:1px;
  margin-top: 5px;
}

.notificationDetailBackground
{
  padding-top:30px;
  padding-bottom: 30px;
  background-color: white;
  padding-left: 30px;
  padding-right: 30px
}

.subscriptionBackground
{
  padding: 35px;
  background-color: white;
}
.titlePadding
{
  padding-left: 50px;
}

.breadCrumbPadding
{
  padding-left:35px;
}

.tabMinHeight
{
  min-height: 200px !important;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden!important;
  text-overflow: ellipsis;
}
.react-datepicker__week
{
  font-size: 12px;
}
.react-datepicker__day
{
  margin-left: 1px !important;
  margin-right: 6px !important;
}
.react-datepicker__header
{
  font-size: 13px !important;
}
.react-datepicker__current-month
{
  font-size: 13px !important;

}
.react-datepicker__month-container
{
  width: 180px !important;
}
.react-datepicker__day-name
{
  margin: 4px !important;
}

input#fromdate { width: 100px; }
